import { useContext, useState } from 'react';
import { GenericButton } from '../../commons/generic-input/GenericButton';
import { ValuePickerWithSlider } from '../../commons/generic-input/ValuePickerWithSlider';
import { apiPost } from '../../../helpers/api';
import { AuthContext } from '../../../contexts/AuthContext';
import { showPrice } from '../../../helpers/functions';
import { toast } from 'react-toastify';
import { FaAngleRight } from 'react-icons/fa';
import { Avatar } from '../../commons/avatar/Avatar';

import styles from "./paymentForm.module.scss";

export const PaymentForm = ({ onSubmitted, isReadOnly, room, balanceDetail }) => {
    const { value: authValue } = useContext(AuthContext);
    const { id: userId, username, avatar } = authValue?.storagedData;

    const [value, setValue] = useState(0);

    const [reciver, setReciver] = useState();

    const isFormValid = () => {
        const errors = {};
        if (!value) {
            errors['value'] = 'El valor no puede estar vacio';
            toast.error("El valor no puede estar vacio");
        }
        if (value === "0") {
            errors['value'] = 'El valor no puede ser 0';
            toast.error("El valor no puede ser 0");
        }

        return Object.keys(errors).length === 0;
    }

    const handleOnSubmit = (e) => {
        e.preventDefault();
        if (isFormValid()) {

            apiPost('payments', {
                roomId: room.id,
                receiverId: reciver.id,
                payerId: userId,
                description: `Pago a ${reciver.username}`,
                value: value,
                date: new Date().toISOString(),
            })
                .then(() => onSubmitted())
        }
    }

    if (!reciver) {
        const recivers = [];

        room.users.forEach(user => {
            const balance = balanceDetail[user.id];

            if (balance > 0) {
                recivers.push({
                    id: user.id,
                    balance: balanceDetail[user.id],
                    avatar: user.avatar,
                    username: user.username
                })
            }
        });

        return (
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                        <Avatar size={"md"} url={avatar} />
                        <div>
                            <div style={{ fontWeight: 500, color: "var(--text-color)" }}>{username}</div>
                            <div className={styles.balance} style={{ color: "#ff6060" }}>{showPrice(balanceDetail[userId])}</div>
                        </div>
                    </div>
                </div>

                <hr />

                <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
                    {
                        recivers.sort((a, b) => a.balance > b.balance ? -1 : 1).map(user => {
                            if (user.balance > 0) {
                                return (
                                    <div key={user.id} style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                            <Avatar size={"md"} url={user.avatar} />
                                            <div>
                                                <div style={{ fontWeight: 500, color: "var(--text-color)" }}>{user.username}</div>
                                                <div className={styles.balance}>{showPrice(user.balance)}</div>
                                            </div>
                                        </div>
                                        <GenericButton
                                            text="Seleccionar"
                                            onClick={() => { setReciver(user); setValue(user.balance > Math.abs(balanceDetail[userId]) ? Math.abs(balanceDetail[userId]) : user.balance); }}
                                            variant="secondary"
                                            fitContent
                                        />
                                    </div>
                                )
                            } else { return null }
                        })
                    }
                </div>
            </div>
        )
    }

    return (
        <form onSubmit={handleOnSubmit} className={isReadOnly ? 'no-click' : ''}>
            <br />

            <div style={{ display: "flex", justifyContent: "center", alignContent: "center", gap: 10 }}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <img src={avatar} alt="" style={{ height: 40, width: 40, borderRadius: 1000 }} />
                    <div>{username}</div>
                </div>
                <div style={{ fontSize: 24, color: "#444", marginTop: 10 }}>
                    <FaAngleRight />
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <img src={reciver.avatar} alt="" style={{ height: 40, width: 40, borderRadius: 1000 }} />
                    <div>{reciver.username}</div>
                </div>
            </div>

            <hr />

            <ValuePickerWithSlider
                min={0.01}
                max={reciver.balance > Math.abs(balanceDetail[userId]) ? Math.abs(balanceDetail[userId]) : reciver.balance}
                name="value"
                value={value}
                onChange={(e) => { setValue(e.target.value) }}
            />
            <br />

            {
                !isReadOnly && (
                    <GenericButton onClick={handleOnSubmit} text={"Enviar"} />
                )
            }
        </form>
    )
}
