import styles from "./styles/addRoomCard.module.scss";

export const AddRoomCard = ({ onCardClick }) => {
    return (
        <div className={styles.container}>
            <div className={styles.addRoomCard} onClick={onCardClick}>
                <span>Crear Sala</span>
            </div>
        </div>
    )
}
