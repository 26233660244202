import { useRef, useState } from 'react'
import CurrencyInput from 'react-currency-input-field';

import styles from "./styles/valuePickerWithSlider.module.scss";

export const ValuePickerWithSlider = ({max, value, name, onChange }) => {

    const [localValue, setlocalValue] = useState(value);

    const [useTotalValue, setUseTotalValue] = useState(true);

    const inputRef = useRef();

    const toggleFixedValue = () => {
        if (useTotalValue) {
            // TODO: ver porque no funca
            inputRef.current.focus();

            setlocalValue('');
            onChange({ target: { name: name, value: 0 } })
        } else {
            setlocalValue(max);
            onChange({ target: { name: name, value: max } })
        }


        setUseTotalValue(prev => !prev);
    }

    return (
        <div>
            <CurrencyInput
                className={styles.currencyInput}
                prefix='$'
                name={name}
                placeholder="Ingresa un valor"
                // defaultValue={localValue}
                value={typeof localValue === "number" ? parseFloat(localValue).toFixed(2) : localValue}
                // decimalsLimit={2}
                onValueChange={(value, name) => { setlocalValue(value); onChange({ target: { name: name, value: value } }) }}
                decimalSeparator=","
                groupSeparator="."
                ref={inputRef}
                disabled={useTotalValue}
            />

            <div style={{display: "flex", justifyContent: "center", gap: 5, marginTop: 5}}>
                <input type="checkbox" name="totalPay" id="totalPay" checked={useTotalValue} onChange={toggleFixedValue} />
                <small>Pago total</small>
            </div>
        </div>
    )
}
