import { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { RoomList } from './room-list/RoomList'
import { SubMenu } from './SubMenu';
import { RoomsContext } from '../../contexts/RoomsContext';
import { AddRoomCard } from './room-form/AddRoomCard';
import useModal from '../../hooks/useModal';
import { EditOrCreateRoom } from '../commons/forms/EditOrCreateRoom';

export const HomeScreen = () => {
    const location = useLocation();

    const { Modal, setModal, hideModal } = useModal();

    const { rooms, roomsStatus, roomsRefetch, archivedRooms, archivedRoomsStatus } = useContext(RoomsContext);

    useEffect(() => {
        document.title = "PayRoom";
    }, []);

    const subMenuItems = [
        {
            label: 'Mis salas',
            path: '/'
        },
        {
            label: 'Salas archivadas',
            path: '/archive'
        }
    ];

    return (
        <>
            <SubMenu items={subMenuItems} />
            {
                location.pathname === '/' && (
                    <>
                        <RoomList rooms={rooms} status={roomsStatus} onUpdate={roomsRefetch} />
                        <AddRoomCard onCardClick={() => setModal(<EditOrCreateRoom onSuccess={() => { roomsRefetch(); hideModal();}} />, { title: "Nueva Sala" })} />
                    </>
                )
            }

            {
                location.pathname === '/archive' && <RoomList rooms={archivedRooms} status={archivedRoomsStatus} hideAdd={true} type='archived' />
            }

            <Modal />
        </>
    )
}