import { useContext, useState } from 'react';
import { useForm } from '../../../hooks/useForm';
import { GenericInput } from '../../commons/generic-input/GenericInput';
import { Link, useNavigate } from 'react-router-dom';
import { GenericButton } from '../../commons/generic-input/GenericButton';
import { apiPost } from '../../../helpers/api';
import { AuthContext } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';

import styles from "../styles/auth.module.scss";

export const RegisterScreen = () => {

    const { setValue } = useContext(AuthContext);

    const navigate = useNavigate();

    const [submitted, setSubmitted] = useState(false);

    const [formErrors, setFormErrors] = useState({});

    const [formValues, handleInputChange] = useForm({
        name: '',
        lastname: '',
        username: '',
        email: '',
        password: '',
        password2: '',
        avatar: 3
    });

    const { name, lastname, username, email, password, password2 } = formValues;

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (isFormValid()) {
            apiPost('users', formValues)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 404 || result.status === 301) {
                        toast.error(result.userMessage);
                    } else {
                        localStorage.setItem('sessionData', JSON.stringify(result));
                        setValue({
                            storagedData: result
                        })
                        navigate("/");
                    }
                })
                .catch(() => toast.error("Algo salio mal"));
        }
    }

    const isFormValid = () => {
        const errors = {};
        if (!name) {
            errors['name'] = 'El nombre no puede estar vacio';
        }
        if (!lastname) {
            errors['lastname'] = 'El apellido no puede estar vacio';
        }
        if (!username) {
            errors['username'] = 'El nombre de usuario no puede estar vacio';
        }
        if (!email) {
            errors['email'] = 'El email no puede estar vacio';
        }
        if (password.length < 8) {
            errors['password'] = 'La contraseña debe ser de al menos 8 caracteres';
        }
        if (password !== password2) {
            errors['password2'] = 'Las contraseñas no coinciden';
        }
        setFormErrors({ ...errors });
        return !Object.keys(errors).length;
    }

    return (
        <div style={{ maxWidth: '400px', margin: 'auto', padding: '20px' }}>
            <div style={{ textAlign: 'center', marginBottom: '30px' }}>
                <img src="/undraw_mobile_payments.svg" alt="" style={{ height: '200px' }} />
            </div>
            <form onSubmit={handleSubmit}>
                <GenericInput
                    label="Nombre de usuario"
                    placeholder="Ingresa tu nickname"
                    name="username"
                    value={username}
                    onChange={handleInputChange}
                    refreshErrors={isFormValid}
                    errors={formErrors['username']}
                    submitted={submitted}
                />

                <GenericInput
                    label="Nombre"
                    placeholder="Ingresa nombre"
                    name="name"
                    value={name}
                    onChange={handleInputChange}
                    refreshErrors={isFormValid}
                    errors={formErrors['name']}
                    submitted={submitted}
                />

                <GenericInput
                    label="Apellido"
                    placeholder="Ingresa apellido"
                    name="lastname"
                    value={lastname}
                    onChange={handleInputChange}
                    refreshErrors={isFormValid}
                    errors={formErrors['lastname']}
                    submitted={submitted}
                />

                <GenericInput
                    label="Email"
                    placeholder="Ingresa tu email"
                    name="email"
                    value={email}
                    onChange={handleInputChange}
                    refreshErrors={isFormValid}
                    errors={formErrors['email']}
                    submitted={submitted}
                />

                <GenericInput
                    type="password"
                    label="Contraseña"
                    placeholder="Ingresa una contraseña"
                    name="password"
                    value={password}
                    onChange={handleInputChange}
                    refreshErrors={isFormValid}
                    errors={formErrors['password']}
                    submitted={submitted}
                />

                <GenericInput
                    type="password"
                    label="Valida tu contraseña"
                    placeholder="Volve a ingresar tu contraseña"
                    name="password2"
                    value={password2}
                    onChange={handleInputChange}
                    refreshErrors={isFormValid}
                    errors={formErrors['password2']}
                    submitted={submitted}
                />

                <GenericButton text="Crear cuenta" onClick={handleSubmit} />
            </form>

            <div className={styles.authRedirectContainer}>
                <Link to="/login">Ya tengo cuenta!</Link>
            </div>
        </div>
    )
}
