import { createContext, useState } from 'react';
import { queryClient } from '../App';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

    const [value, setValue] = useState({
        storagedData: JSON.parse(localStorage.getItem('sessionData')) || {}
    });

    const logout = () => {
        queryClient.clear();
        localStorage.clear();
        setValue({storagedData: null});
    }

    return (
        <AuthContext.Provider value={{ value, setValue, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
