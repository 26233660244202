import { useNavigate } from 'react-router-dom';
import { Separator } from '../commons/separator/Separator';

import styles from "./styles/infoFrame.module.scss";

export const InfoFrame = ({ activeRooms, archivedRooms }) => {
    const navigate = useNavigate();
    return (
        <>
            <Separator />
            <div className={styles.container}>
                <div className={styles.item} onClick={() => navigate('/')}>
                    <h4>{activeRooms}</h4>
                    <span>Salas activas</span>
                </div>
                <div className={styles.item} onClick={() => navigate('/archive')}>
                    <h4>{archivedRooms}</h4>
                    <span>Salas archivadas</span>
                </div>
            </div>
            <Separator />
            <br />
        </>
    )
}
