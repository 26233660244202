import styles from "./styles/genericInput.module.scss";

export const GenericSelect = ({ label, name, value, options, onChangeSelect }) => {
    return (
        <div className={styles['generic-input-container']}>
            <span className={styles['generic-input-label']}>{label}</span>
            <select name={name} id={name} value={value} onChange={(e) => onChangeSelect(e)}>
                {
                    options.map(option => (
                        <option key={option.value} value={option.value}>{option.description}</option>
                    ))
                }
            </select>
        </div>
    )
}
