export const addDots = (nStr) => {
    nStr += '';
    const x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, `$1.$2`); // changed comma to dot here
    }
    return x1 + x2;
}

export const showPrice = (number) => {
  if (!number){
    return '$0';
  }
  return `${number < 0 ? '-' : ''}$${addDots(Math.abs(number)?.toFixed(2).replace(/(\.0+|0+)$/, '') || 0)}`
}

export const stringToColour = (str) => {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var colour = '#';
    for (var j = 0; j < 3; j++) {
      var value = (hash >> (j * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}

export const dateToString = (date, showTime = true) => {
  const now = new Date();
  const dateToShow = new Date(date);

  const diffTime = Math.abs(dateToShow - now);
  const diffHours = diffTime / (1000 * 60 * 60); 
  const diffMinutes = diffTime / (1000 * 60); 
  const diffSeconds = diffTime / (1000);

  if (diffSeconds < 60 && !showTime){
    return `${~~diffSeconds}s`;
  }

  if (diffMinutes < 60 && !showTime){
    return `${~~diffMinutes}m`;
  }

  if (diffHours < 24 && !showTime){
    return `${~~diffHours}h`;
  }


  const options = {
    month: "short",
    day: "numeric"
  }

  const diff = (now.getTime() - dateToShow.getTime()) / (1000 * 3600 * 24);

  if ((parseInt(diff)).toFixed(0) > 365) {
    options['year'] = 'numeric';
    options['month'] = 'numeric';
    
  }

  if (showTime) {
    options['hour'] = 'numeric';
    options['minute'] = 'numeric';
  }

  return dateToShow.toLocaleDateString('es-AR', options)

  // switch (diffDays) {
  //   case diffDays < 1:
  //     return 'hoy'
  //   case diffDays > 1:
  //     return 'ayer'
  //   default:
  //     return dateToShow.toLocaleDateString('es-AR', options)
  // }

  // if (isToday(new Date(date)) && !showTime) {
  //   return 'hoy'
  // } else { 
  //   return dateToShow.toLocaleDateString('es-AR', options)
  // }
}
