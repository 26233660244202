import classNames from 'classnames';
import { useEffect, useState } from 'react'

import styles from "./styles/participantsSelector.module.scss";

export const ParticipantsSelector = ({ participants, expenseParticipants, onParticipantsChange, expenseValue }) => {
    const [selectedParticipants, setSelectedParticipants] = useState(expenseParticipants || []);

    useEffect(() => {
        setSelectedParticipants(expenseParticipants || [...participants]);
    }, [expenseParticipants]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleClick = (participant) => {
        if (selectedParticipants.find(sp => sp.id === participant.id)) {
            onParticipantsChange([...selectedParticipants.filter(sp => sp.id !== participant.id)])
            setSelectedParticipants([...selectedParticipants.filter(sp => sp.id !== participant.id)]);
        } else {
            onParticipantsChange([...selectedParticipants, participant])
            setSelectedParticipants([...selectedParticipants, participant]);
        }
    }

    const getPercentageValue = () => {
        if (expenseValue % selectedParticipants.length) {
            return (expenseValue / selectedParticipants.length).toFixed(2)
        } else {
            return expenseValue / selectedParticipants.length;
        }
    }

    return (
        <div className={styles['participants-selector-container']}>
            <p>
                Dividir entre {selectedParticipants.length}
                {
                    expenseValue && selectedParticipants.length > 1 && (
                        ` • $${getPercentageValue()} c/u`
                    )
                }
            </p>
            <div className={styles['participants-container']}>
                {
                    participants.sort((a, b) => a.id > b.id).map(participant => (
                        <div
                            key={participant.id}
                            className={classNames(styles['participant-container'], { [styles.selected]: selectedParticipants.find(sp => sp.id === participant.id) })}
                            onClick={() => handleClick(participant)}
                        >
                            <div className={styles.imageContainer}>
                                <img src={participant.avatar} alt={participant.username} />
                            </div>
                            <span>
                                {participant.username}
                            </span>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
