import { useState } from "react";
import { Modal as GenericModal } from "../components/commons/modal/Modal";

interface ModalProps {
    children?: JSX.Element,
    onClose?: () => void,
    size?: string,
    type?: string,
    title?: string,
    mandatory?: boolean,
}

export interface SetModalOptions {
    size?: string
    title?: string
    onCloseCallback?: () => void
    headerImage?: JSX.Element
    closeOnClickOutside?: boolean
    hideCloseButton?: boolean
}

const useModal = () => {

    const [modalTitle, setModalTitle] = useState<string>();
    const [isModalOpen, setIsModalOpen] = useState<boolean>();
    const [children, setChildren] = useState<JSX.Element | JSX.Element[]>();

    const changeModalTitle = (title: string): void => {
        setModalTitle(title);
    };

    const hideModal = (): void => {
        setIsModalOpen(false);
    };

    const showModal = (): void => {
        setIsModalOpen(true);
    };

    const setModal = (children: JSX.Element | JSX.Element[], options?: SetModalOptions): void => {
        const { title } = options || {};
        setChildren(children);
        setModalTitle(title);
        showModal();
    };

    const Modal = (props: ModalProps): JSX.Element | null => {
        const { children: childrenProp } = props;

        if (!isModalOpen) return null;

        return <GenericModal
            show={isModalOpen}
            onClose={hideModal}
            title={modalTitle || ""}
            {...props}
        >
            {(children || childrenProp) as JSX.Element | JSX.Element[]}
        </GenericModal>;
    };

    return { Modal, changeModalTitle, showModal, hideModal, isModalOpen, setModal };
};

export default useModal;