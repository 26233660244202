import { useContext, useState } from "react";
import { GenericInput } from "../generic-input/GenericInput";
import { CoverPageSelector } from "../cover-page-selector/CoverPageSelector";
import { GenericButton } from "../generic-input/GenericButton";
import { useForm } from "../../../hooks/useForm";
import { AuthContext } from "../../../contexts/AuthContext";
import { apiPost, apiPostWithAppend } from "../../../helpers/api";
import { toast } from "react-toastify";

export const EditOrCreateRoom = ({ onSuccess }) => {
    const [formErrors, setFormErrors] = useState({});

    const [submitted, setSubmitted] = useState(false);

    const [uploadImage, setUploadImage] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const { value } = useContext(AuthContext);
    const id = value?.storagedData?.id;

    const [formValues, handleInputChange, resetForm, changeValue] = useForm({
        name: '',
        description: '',
        coverpage: '',
        owner: id
    })

    const { name, description, coverpage, owner } = formValues;

    const handleSelectCover = (coverId) => {
        isFormValid();
        changeValue('coverpage', coverId);
    }

    const handleSubmit = (e) => {
        // TODO: usar await para la carga de coverpage en caso de ser necesaria

        e.preventDefault();
        setSubmitted(true);
        if (isFormValid()) {
            setIsLoading(true);
            if (coverpage !== -1) {
                apiPost('rooms', {
                    name,
                    description,
                    owner,
                    coverpage: coverpage
                })
                    .then(() => onSuccess())
                    .catch(error => console.error(error))
                    .finally(() => {
                        setIsLoading(false);
                        resetForm();
                    })
            } else {
                var data = new FormData()
                data.append('file', uploadImage)
                data.append('name', uploadImage.name)

                apiPostWithAppend('coverpages', data)
                    .then(response => response.json())
                    .then(result => {
                        if (!result.id) throw new Error("Failed coverpage");
                        apiPost('rooms', {
                            name,
                            description,
                            owner,
                            coverpage: result.id
                        })
                            .then(() => onSuccess())
                            .catch(error => console.error(error))
                            .finally(() => {
                                resetForm();
                                setIsLoading(false);
                            });
                    })
                    .catch(() => toast.error('Ocurrio un error al subir la imagen'));
            }
        }
    }

    const isFormValid = () => {
        const errors = {};
        if (!name) {
            errors['name'] = 'El nombre no puede estar vacio';
        }
        if (!coverpage) {
            errors['coverpage'] = 'Se debe seleccionar una imagen de portada';
        }
        setFormErrors({ ...errors });
        return Object.keys(errors).length === 0;
    }

    return (
        <form onSubmit={handleSubmit}>
            <GenericInput
                placeholder="Ingresa el nombre de la sala"
                name="name"
                value={name}
                onChange={handleInputChange}
                label="Nombre"
                refreshErrors={isFormValid}
                errors={formErrors['name']}
                submitted={submitted}
            />

            <GenericInput
                placeholder="Ingresa una descripcion"
                name="description"
                value={description}
                onChange={handleInputChange}
                label="Descripcion"
                refreshErrors={isFormValid}
                errors={formErrors['description']}
                submitted={submitted}
            />

            <CoverPageSelector
                onSelectCover={handleSelectCover}
                onSelectUpload={setUploadImage}
                error={submitted && formErrors['coverpage']}
            />

            <GenericButton loading={isLoading} text="Crear sala" onClick={handleSubmit} />
        </form>
    )
}
