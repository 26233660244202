import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames'

import styles from "./styles/subMenu.module.scss";

export const SubMenu = ({ items }) => {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <div className={styles['sub-menu-container']}>
            {
                items.map((item, index) => (
                    <div className={styles['sub-menu-item']} key={index}>
                        <span
                            className={classNames(styles['sub-menu-item-text'], { [styles.selected]: item.path === location.pathname })}
                            onClick={() => navigate(item.path)}
                        >
                            {item.label}
                        </span>
                    </div>
                ))
            }
        </div>
    )
}
