import { useContext, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { GenericButton } from '../../commons/generic-input/GenericButton';
import { GenericInput } from '../../commons/generic-input/GenericInput'
import { apiPost } from '../../../helpers/api';
import { AuthContext } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';

import styles from "../styles/auth.module.scss";

export const RegisterUsername = () => {
    const [username, setUsername] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    const { setValue } = useContext(AuthContext);

    const tempToken = localStorage.getItem('temporalUserToken');
    const tempName = localStorage.getItem('temporalUsername');

    const navigate = useNavigate();

    const handleError = () => {
        setFormErrors({ ...formErrors, username: 'Ya existe el nombre de usuario' })
    }

    const handleSubmit = () => {
        setSubmitted(true);
        if (isFormValid()) {
            apiPost('social-login', { token: tempToken, username: username })
                .then(response => {
                    if (response.status === 418) {
                        localStorage.setItem('temporalUserToken', tempToken);
                        localStorage.setItem('temporalUsername', tempName)
                        handleError();
                    }
                    return response.json()
                })
                .then(result => {
                    if (result.status === 404 || result.status === 301) {
                        toast.error('Ocurrio un error');
                    } else {
                        localStorage.setItem('sessionData', JSON.stringify(result));
                        setValue({
                            storagedData: result
                        })
                        navigate("/");
                    }
                })
                .catch(error => { toast.error(error) });
        }
    }

    const isFormValid = () => {
        const errors = {};
        if (!username.trim().length) {
            errors['username'] = 'El nombre de usuario no puede estar vacio';
        }
        setFormErrors({ ...errors });
        return !Object.keys(errors).length;
    }

    if (!localStorage.getItem('temporalUserToken')) {
        return <Navigate to="/login" />
    }

    return (
        <div className={styles.container}>
            <div className={styles.imageContainer}>
                <img src="/undraw_pull_request.svg" alt="" />
            </div>

            <h3 className={styles.title}>Hola {tempName}!</h3>
            <GenericInput
                label=""
                placeholder="Ingresa tu nuevo nombre de usuario"
                name="username"
                value={username}
                onChange={(e) => { setUsername(e.target.value) }}
                refreshErrors={isFormValid}
                errors={formErrors['username']}
                submitted={submitted}
            />

            <GenericButton text="Crear usuario" onClick={handleSubmit} />

            <div className={styles.backButton}>
                <Link to="/login">Volver</Link>
            </div>
        </div>
    )
}
