import classNames from "classnames";
import styles from "./styles/genericInput.module.scss";

export const GenericButton = ({ onClick, loading, text, variant, disabled, fitContent, fullWidth, shadow, children }) => {
    return (
        <button
            className={classNames(
                styles['generic-button'],
                {
                    [styles['secondary-button']]: variant === 'secondary',
                    [styles['tertiary-button']]: variant === 'tertiary',
                    [styles['red-button']]: variant === 'red',
                    [styles.fitContent]: fitContent,
                    [styles.shadow]: shadow,
                    [styles.fullWidth]: fullWidth,
                    [styles.loading]: loading
                }
            )}
            onClick={onClick}
            disabled={disabled}
        >
            {children || text}
        </button>
    )
}
