import { useState } from 'react';

import styles from "./styles/genericInput.module.scss";

export const GenericDatePicker = ({ value, onChangeValue }) => {


    const handleInputChange = (e) => {
        setDate(e.target.value);
        onChangeValue(e.target.value);
    }

    const addZero = (number) => {
        return ("0" + number).slice(-2);
    }

    const formatDate = (date) => {
        return `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(date.getDate())}T${addZero(date.getHours())}:${addZero(date.getMinutes())}`;
    }

    const [date, setDate] = useState((value && formatDate(new Date(value?.toString()))) || formatDate(new Date()));

    return <div>
        <span className={styles['generic-input-label']}>Fecha</span> <br />
        <input
            className={styles['generic-input']}
            value={date}
            onChange={handleInputChange}
            type="datetime-local"
            id="birthdaytime"
            name="birthdaytime"
            pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
            lang="fr-CA"
        ></input>
        <br />
    </div>;
};
