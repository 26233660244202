import { useEffect, useState } from 'react'
import { IoImageOutline } from "react-icons/io5";
import classNames from 'classnames';
import { apiGet } from '../../../helpers/api';

import styles from "./coverPageSelector.module.scss";

export const CoverPageSelector = ({ currentCover, onSelectCover, onSelectUpload, error }) => {
    const [selectedImage, setSelectedImage] = useState(currentCover?.id || null)
    const [uploadedImage, setUploadedImage] = useState(null);
    const [coverpages, setCoverpages] = useState([]);

    useEffect(() => {
        apiGet(`coverpages`)
            .then(response => response.json())
            .then(result => {
                if (result.length) setCoverpages(result);
            })
            .catch(error => console.error(error.status));
    }, [])


    const handleInputChange = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            setUploadedImage({
                file: file,
                imagePreviewUrl: reader.result
            });
        }

        reader.readAsDataURL(file)

        onSelectCover(-1);
        setSelectedImage(-1);
        onSelectUpload(file);
    }

    return (
        <div>
            {
                error && (
                    <span className={styles.errorMsg} style={{ position: 'initial' }}>{error}</span>
                )
            }

            <div className={styles.coverpagesSelector}>
                <div className={styles.child}>
                    <div className={styles['upload-img-container']}>
                        {
                            !uploadedImage
                                ? (
                                    <div className={styles['upload-input']}>
                                        <label htmlFor="upload">
                                            <IoImageOutline size={50} className={styles.uploadIcon} />
                                            <span>Subir imagen</span>
                                            <input
                                                type="file"
                                                id="upload"
                                                style={{ display: 'none' }}
                                                accept="image/*"
                                                onChange={handleInputChange}
                                            />
                                        </label>
                                    </div>
                                )
                                : (
                                    <div
                                        className={classNames(styles['upload-preview'], { [styles['selected-img']]: selectedImage === -1 })}
                                        onClick={() => { setSelectedImage(-1); onSelectCover(-1); }}
                                    >
                                        <img src={uploadedImage?.imagePreviewUrl} alt="Portada subida" />
                                        <span onClick={() => setUploadedImage(null)}>✕</span>
                                    </div>
                                )
                        }
                    </div>
                </div>
                {
                    currentCover && (
                        <div className={styles.child}>
                            <div
                                className={classNames(styles['child-img-container'], { [styles['selected-img']]: currentCover.id === selectedImage })}
                                onClick={() => { setSelectedImage(currentCover.id); onSelectCover(currentCover.id) }}
                            >
                                <img src={currentCover.url} alt="main" key={currentCover.id} />
                            </div>
                        </div>
                    )
                }
                {
                    coverpages.filter(coverpage => coverpage.id !== currentCover?.id).map(coverpage => (
                        <div className={styles.child} key={coverpage.id}>
                            <div
                                className={classNames(styles['child-img-container'], { [styles['selected-img']]: coverpage.id === selectedImage })}
                                onClick={() => { setSelectedImage(coverpage.id); onSelectCover(coverpage.id) }}
                            >
                                <img src={coverpage.url} alt="main" key={coverpage.id} />
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
