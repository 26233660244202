import styles from "./styles/mockupItem.module.scss";

export const MockupItem = () => {
    return (
        <div className={styles.mockupCard}>
            <div className={styles.favoriteIcon}></div>
            <div className={styles.title}></div>
            <div className={styles.date}></div>
        </div>
    )
}
