import { useContext, useState } from 'react';
import Popup from 'reactjs-popup';
import { useNavigate } from 'react-router-dom';
import { EditRoom } from '../EditRoom';
import { useEffect } from 'react';
import { showPrice } from '../../../helpers/functions';
import { GenericButton } from '../../commons/generic-input/GenericButton';
import classNames from 'classnames';
import { ManageUsers } from '../ManageUsers';
import { apiDelete, apiPatch } from '../../../helpers/api';
import useModal from '../../../hooks/useModal';
import { UiContext } from '../../../contexts/UiContext';
import { RoomsContext } from '../../../contexts/RoomsContext';
import { FaArrowLeft, FaEllipsisH, FaUsers } from "react-icons/fa";

import styles from "./roomBanner.module.scss";

export const RoomBanner = ({ title, persons, img, id, room, isArchived, onRefetch }) => {

    const [totalExpense, setTotalExpense] = useState(0);

    const {setValue: setUiValue} = useContext(UiContext);
    const {roomsRefetch} = useContext(RoomsContext);

    const navigate = useNavigate();

    const { Modal, setModal, hideModal } = useModal();

    const handleBack = () => {
        navigate('/');
    }

    const handleToggleArchive = () => {
        setUiValue({loading: true})

        if (isArchived) {
            apiPatch(`rooms/${id}/user`)
                .then(response => response.text())
                .then(() => {roomsRefetch(); onRefetch(); navigate('/')})
                .catch(error => alert(error))
                .finally(() => setUiValue({loading: false}))
        } else {
            apiDelete(`rooms/${id}/user`)
                .then(response => response.text())
                .then(() => {roomsRefetch(); onRefetch(); navigate('/')})
                .catch(error => alert(error))
                .finally(() => setUiValue({loading: false}));
        }
    }

    useEffect(() => {
        setTotalExpense(room.totalSpent);
    }, [room]);

    return (
        <div style={{ marginBottom: '20px' }}>
            <div className={styles['img-container']}>
                {
                    img && (
                        <img
                            src={img || ''}
                            alt={title}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = "https://hornmagazine.online/wp-content/uploads/sites/26/2021/02/No-Image.jpg";
                            }}
                        />
                    )
                }
                {
                    isArchived && (
                        <span className={styles['archived-field']}>Sala archivada</span>
                    )
                }
                <div className={styles['title-container']}>
                    <span className={styles.title}>{title}</span>
                    <span className={styles.subtitle}><FaUsers size={20} /> {persons.length} • {showPrice(totalExpense)}</span>
                </div>
                <div className={classNames(styles['action-button'], styles['back-button'])} onClick={handleBack}>
                    <FaArrowLeft />
                </div>

                <Popup
                    trigger={
                        <div className={classNames(styles['action-button'], styles['edit-button'])}>
                            <FaEllipsisH />
                        </div>
                    }
                    position="left top"
                >
                    <div className={styles['expense-options']}>
                        {
                            !isArchived && room.isAdmin && (
                                <div className={styles.option} onClick={() => setModal(
                                    <EditRoom room={room} onSubmit={() => { hideModal(); onRefetch() }} />,{
                                        title: `Editando "${title}"`
                                    }
                                )}>
                                    Editar sala
                                </div>
                            )
                        }

                        <div className={styles.option}
                            onClick={() => setModal(
                                <ManageUsers room={room} onUpdate={onRefetch} />,
                                { title: "Participantes" }
                            )}
                        >
                            Participantes
                        </div>

                        <div className={styles.option} style={{ color: '#e34848' }} onClick={() => setModal(
                            <div>
                                <p>{isArchived ? 'Esta sala volvera a aparecer entre tus salas' : 'Dejaras de ver esta sala'}</p>
                                <br />
                                <GenericButton onClick={handleToggleArchive} text={isArchived ? 'Desarchivar' : 'Archivar'} />
                            </div>, {
                            title: `${isArchived ? 'Desarchivar' : 'Archivar'} "${title}"`
                        })}
                        >
                            {isArchived ? 'Desarchivar sala' : 'Archivar sala'}
                        </div>
                    </div>
                </Popup>
            </div>

            <Modal />
        </div>
    )
}
