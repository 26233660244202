import { createContext } from 'react';
import { useQuery } from 'react-query';
import { apiGet } from '../helpers/api';
import { useParams } from 'react-router-dom';

export const RoomContext = createContext();

export const RoomProvider = ({ children }) => {
  const { id: roomId } = useParams();

  const { data, refetch } = useQuery({
    queryKey: ['room', roomId],
    queryFn: () => apiGet(`rooms/${roomId}`).then(response => response.json()),
    refetchOnWindowFocus: false,
    staleTime: 60000,
  })

  return (
    <RoomContext.Provider value={{ value: data, refetch }}>
      {children}
    </RoomContext.Provider>
  );
};
