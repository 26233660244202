import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiGet, apiPost } from '../../../helpers/api';
import { GenericButton } from '../../commons/generic-input/GenericButton';
import { GenericInput } from '../../commons/generic-input/GenericInput';
import { Avatar } from '../../commons/avatar/Avatar';

import styles from "./styles/addParticipantsSelector.module.scss";

export const AddParticipantsSelector = ({ participants = [], onUpdate }) => {
    const { id: roomId } = useParams();

    const [optionList, setOptionList] = useState([]);

    const [selectedParticipants, setSelectedParticipants] = useState([]);

    const [options, setOptions] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const handleSearchChange = (e) => {
        const compareParticipant = (obj, str) => {
            return obj.username.toLowerCase().includes(str.toLowerCase()) ||
                obj.name.toLowerCase().includes(str.toLowerCase()) ||
                obj.lastname.toLowerCase().includes(str.toLowerCase())
        }

        const search = e.target.value;
        setSearchValue(search);
        if (search.length >= 3) {
            setOptions(optionList.filter(option => { return !selectedParticipants.map(user => user.id).includes(option.id) && compareParticipant(option, search) }));
        } else {
            setOptions([]);
        }
    }

    const handleParticipantSelect = (participant) => {
        setOptions([]);
        setSearchValue('');
        setSelectedParticipants(prev => [participant, ...prev]);
    }

    const handleRemoveParticipant = (userId) => {
        setSelectedParticipants(prev => prev.filter(user => user.id !== userId));
    }

    const handleSubmit = () => {
        if (!selectedParticipants.length) {
            alert('Error, no se pudo agregar al usuario')
        } else {
            setIsLoading(true);
            apiPost(`rooms/${roomId}/add-participants`, selectedParticipants.map(user => user.id))
                .then(() => onUpdate())
                .catch(error => alert(error))
                .finally(() => setIsLoading(false));
        }
    }

    useEffect(() => {
        apiGet('users')
            .then(response => response.json())
            .then(dbUsers => setOptionList(
                (participants.length || selectedParticipants.length)
                    ? dbUsers.filter(user => {
                        return !participants.map(participant => participant.id).includes(user.id)
                    })
                    : dbUsers
            ));
    }, [participants, selectedParticipants])

    return (
        <div className={styles.addParticipantsSelectorContainer}>

            {/* Search inpunt */}
            <GenericInput placeholder="Comenza a escribir el nombre de usuario" value={searchValue} onChange={handleSearchChange} />

            {
                !!options.length && (
                    <div className={styles.optionsContainer}>
                        {
                            options.map(option => (
                                <div className={styles.optionItem} key={option.id} onClick={() => handleParticipantSelect(option)}>
                                    <Avatar size={"xsm"} url={option.avatar} title={option.username} />

                                    <span>
                                        {option.username} <small> - {option.name} {option.lastname}</small>
                                    </span>
                                </div>
                            ))
                        }
                    </div>
                )
            }

            {
                options.length === 0 && searchValue.length > 0 && searchValue.length < 3 && (
                    <div className={styles.optionsContainer}>
                        <div className={styles.noResultsMessage}>Ingresa al menos 3 letras</div>
                    </div>
                )
            }

            {
                options.length === 0 && searchValue.length > 3 && (
                    <div className={styles.optionsContainer}>
                        <div className={styles.noResultsMessage}>No se encontraron resultados</div>
                    </div>
                )
            }


            {/* Users to add list */}
            {
                selectedParticipants.map(participant => (
                    <div key={participant.id} className={styles.selectedParticipantInfo}>
                        <div className={styles.participant}>
                            <Avatar url={participant.avatar} title={participant.username} />
                            <div>
                                <span className={styles.participantUsername}>{participant.username}</span>
                                <span className={styles.participantName}>
                                    {participant.name} {participant.lastname}
                                </span>
                            </div>
                        </div>
                        <span className={styles.participantRemove} onClick={() => handleRemoveParticipant(participant.id)}>Remover</span>
                    </div>
                ))
            }

            {/* Submmit button */}
            <GenericButton loading={isLoading} disabled={!selectedParticipants.length} onClick={handleSubmit} text="Agregar" />
        </div>
    )
}
