import { createContext } from 'react';
import { useQuery } from 'react-query';
import { apiGet } from '../helpers/api';
import { useLocation } from 'react-router-dom';

export const RoomsContext = createContext();

export const RoomsProvider = ({ children }) => {
    const location = useLocation();

    const { data: rooms, status: roomsStatus, refetch: roomsRefetch } = useQuery({
        queryKey: ['rooms'],
        queryFn: () => apiGet('users/rooms').then(response => response.json()),
        enabled: ['/', '/me'].includes(location.pathname),
        refetchOnWindowFocus: false,
        staleTime: 60000,
    })

    const { data: archivedRooms, status: archivedRoomsStatus, refetch: archivedRoomsRefetch } = useQuery({
        queryKey: ['archivedRooms'],
        queryFn: () => apiGet('users/rooms/archived').then(response => response.json()),
        enabled: ['/archive', '/me'].includes(location.pathname),
        refetchOnWindowFocus: false,
        staleTime: 60000,
    });

    const contextValue = {
        rooms: rooms,
        roomsStatus: roomsStatus,
        roomsRefetch: roomsRefetch,
        archivedRooms: archivedRooms,
        archivedRoomsStatus: archivedRoomsStatus,
        archivedRoomsRefetch: archivedRoomsRefetch
    }

    return (
        <RoomsContext.Provider value={contextValue}>
            {children}
        </RoomsContext.Provider>
    );
};
