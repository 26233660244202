import styles from "./styles/actionList.module.scss";

export const ActionList = ({ actions }) => {

    return (
        <div>
            {
                actions.map(({ label, variant, icon, onClick }) => (
                    <div key={label} className={`${styles.actionItem} ${styles[variant]}`} onClick={onClick}>
                        {
                            icon && (
                                <span className={styles.icon}>
                                    {icon}
                                </span>
                            )
                        }
                        {label}
                    </div>
                ))
            }
        </div>
    )
}
