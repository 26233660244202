import { Routes, Route } from "react-router-dom";
import { HomeScreen } from "../components/home/HomeScreen";
import { ProfileScreen } from "../components/profile/ProfileScreen";
import { RoomScreen } from "../components/room/RoomScreen";
import { Navbar } from "../components/commons/navbar/Navbar"
import { RoomsProvider } from "../contexts/RoomsContext";

export const AppRoutes = () => {
    return (
        <>
            <RoomsProvider>

                <Navbar />
                <div className="app-container">
                    <Routes>
                        <Route path="/" element={<HomeScreen />} />
                        <Route path="/archive" element={<HomeScreen />} />
                        <Route path="room/:id" element={<RoomScreen />} />
                        <Route path="/me" element={<ProfileScreen />} />
                    </Routes>
                </div>
                <div style={{ textAlign: 'center', fontSize: '12px', color: '#888', marginBottom: 80 }}>
                    PayRoom - 2024
                </div>
            </RoomsProvider>
        </>
    )
}
