import { showPrice } from '../../helpers/functions';
import classNames from 'classnames';
import { Avatar } from '../commons/avatar/Avatar';

import styles from "./styles/participantsList.module.scss";

export const ParticipantsList = ({ participants, expensesDetail, totalDetail }) => {

    return (
        <div className={styles.participantsListSelector}>
            <div className={styles.topRow}>
                <div>
                    Participantes
                </div>
                <div></div>
                <div style={{ textAlign: "end" }}>
                    Balance
                </div>
            </div>

            {
                participants && participants.sort((a, b) => (totalDetail[a.id] || 0) < (totalDetail[b.id] || 0) ? 1 : -1).map(participant => (
                    <div
                        key={participant.id}
                        className={classNames(styles.participant, { [styles.inactive]: !participant.active })}
                    >
                        <div style={{ display: 'flex', overflow: 'auto', gap: 10, alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                            <div style={{ display: 'flex', overflow: 'auto', gap: 10, alignItems: "center" }}>


                                <Avatar size={"md"} url={participant?.avatar} title={participant.username} />

                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <span className={styles.participantUsername}>
                                        {participant.username}
                                    </span>

                                    <span className={styles.participantName}>
                                        {participant.name} {participant.lastname}
                                    </span>
                                </div>

                            </div>

                            <div style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                                <span
                                    style={{ fontWeight: 500 }}
                                    className={classNames(styles.neutralBalance, {
                                        [styles.positiveBalance]: parseFloat(expensesDetail[participant.id]).toFixed() > 0,
                                        [styles.negativeBalance]: expensesDetail[participant.id] < 0
                                    })}
                                >
                                    {Boolean(expensesDetail[participant.id]) ? showPrice(expensesDetail[participant.id]) : "$0"}
                                </span>

                                <span className={styles.total}>
                                    {showPrice(totalDetail[participant.id])}
                                </span>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}
