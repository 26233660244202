import { MockupItem } from './MockupItem';
import { RoomItem } from './RoomItem'

import styles from "./styles/roomList.module.scss";

export const RoomList = ({ rooms, hideAdd = false, type = 'regular', status, onUpdate }) => {

    const customSort = (a, b) => {
        if (b.isFavorite === a.isFavorite) {
            return new Date(b.lastUpdate) - new Date(a.lastUpdate);
        }
        return b.isFavorite > a.isFavorite ? 1 : -1;
    };

    if (status === "loading") return (
        <div className={styles.roomList}>
            <div className={styles.roomsContainer}>
                <MockupItem />
                <MockupItem />
                <MockupItem />
                <MockupItem />
            </div>
        </div>
    )

    return (
        <div className={styles.roomList}>
            <div className={styles.roomsContainer}>
                {
                    rooms.sort(customSort).map(room => (
                        <RoomItem data={room} key={room.id} isArchived={type === 'archived'} onUpdate={onUpdate} />
                    ))
                }
            </div>
            {
                !rooms.length && status === "success" && hideAdd && (
                    <p style={{
                        textAlign: 'center',
                        fontSize: '12px',
                        color: '#666',
                        marginTop: '50px'
                    }}
                    >No hay salas</p>
                )
            }
        </div>
    )
}
