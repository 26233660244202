import classNames from "classnames";
import styles from "./avatar.module.scss";

interface Props {
  url: string;
  title?: string;
  size?: string; 
}

export const Avatar = (props: Props) => {
  const {url, title, size} = props;

  return (
    <div className={classNames(styles.container, styles[size as any])}>
        <img className={styles.image} src={url} alt={title} title={title} />
    </div>
  )
}
