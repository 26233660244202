import { useContext, useState } from 'react';
import { AuthContext } from '../../../contexts/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { BiHomeSmile } from 'react-icons/bi';
import { TbSettings } from 'react-icons/tb';
import { useClickListener } from '../../../hooks/useClickListener';
import { Avatar } from '../avatar/Avatar';
import classNames from 'classnames';
import { GenericButton } from '../generic-input/GenericButton';
import useModal from '../../../hooks/useModal';
import { EditOrCreateRoom } from '../forms/EditOrCreateRoom';
import { RoomsContext } from '../../../contexts/RoomsContext';

import styles from "./navbar.module.scss";

const Menu = ({ onClose }) => {

    const { roomsRefetch } = useContext(RoomsContext);

    const { value } = useContext(AuthContext);
    const { avatar, username } = value?.storagedData;

    const { Modal, setModal, hideModal } = useModal();

    const navigate = useNavigate();

    const location = useLocation();

    const menuItems = [
        {
            label: "Inicio",
            href: "/",
            icon: <BiHomeSmile size={16} />
        },
        {
            label: "Mi Cuenta",
            href: "/me",
            icon: <TbSettings />
        }
    ]

    const { ref } = useClickListener(onClose);

    return (
        <div ref={ref} className={styles.menu}>
            <div className={styles.userInfo}>
                <Avatar size={"sm"} url={avatar} />
                <div style={{ fontWeight: 500 }}>{username}</div>
            </div>

            <hr />

            <div className={styles.items}>
                {
                    menuItems.map((item, index) => (
                        <div key={index} className={classNames(styles.item, { [styles.active]: location.pathname === item.href })} onClick={() => { navigate(item.href); onClose() }}>
                            {item.icon} {item.label}
                        </div>
                    ))
                }
            </div>

            <hr />

            <GenericButton
                fitContent
                onClick={
                    () => setModal(<EditOrCreateRoom onSuccess={() => { roomsRefetch(); hideModal(); onClose(); navigate("/") }} />, { title: "Nueva Sala" })
                }
            >
                Crear Sala
            </GenericButton>

            <Modal />
        </div>
    )
}

export const Navbar = () => {
    const navigate = useNavigate();
    const [showMenu, setShowMenu] = useState(false);

    return (
        <div>
            <div style={{ height: `${window.statusBarHeight ? 55 + window.statusBarHeight : 55}px` }}></div>
            <nav className={styles.navbar} style={{ paddingTop: `${window.statusBarHeight}px` }}>
                <div className={styles.navSubcontainer}>
                    <h1 onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>PayRoom</h1>

                    <div style={{ position: "relative", cursor: 'pointer' }}>
                        <div className={classNames(styles.burgerMenu, { [styles.active]: showMenu })} onClick={() => setShowMenu(true)}>
                            <div className={classNames(styles.line, styles.line1)}></div>
                            <div className={classNames(styles.line, styles.line2)}></div>
                            <div className={classNames(styles.line, styles.line3)}></div>
                        </div>

                        {showMenu && <Menu onClose={() => setShowMenu(false)} />}
                    </div>
                </div>
            </nav>
        </div>
    )
}