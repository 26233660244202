import classNames from "classnames";
import { useState } from "react";

import styles from "./toggleDarkMode.module.scss";

export const ToggleDarkMode = () => {

    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light-theme')

    const handleToggleTheme = () => {
        if (theme === 'light-theme') {
            setTheme('dark-theme');
            localStorage.setItem('theme', 'dark-theme');
            document.body.classList.toggle("dark-theme");
        } else {
            setTheme('light-theme');
            localStorage.setItem('theme', 'light-theme');
            document.body.classList.toggle("dark-theme");
        }
    }

    return (
        <div className={styles.buttonContainer}>
            {/* <button onClick={handleToggleTheme}>Cambiar tema</button> */}
            {/* <p>
                {theme === 'light-theme' ? 'Activar modo oscuro' : 'Activar modo claro'}
            </p> */}

            {/* <label className={styles.switch}>
                <input type="checkbox"/>
                    <span className={styles.slider}></span>
            </label> */}

            <label className={styles.switch}>
                <input type="checkbox" checked={theme === 'dark-theme'} onClick={handleToggleTheme}/>
                <span className={classNames(styles.slider, styles.round)}></span>
            </label>
            Modo oscuro
        </div>
    )
}
