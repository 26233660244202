import { useContext, useState } from 'react';
import Popup from 'reactjs-popup';
import { dateToString, showPrice } from '../../helpers/functions';
import { ExpenseEditOrCreateForm } from './expense/ExpenseEditOrCreateForm';
import classNames from 'classnames';
import { Modal } from '../commons/modal/Modal';
import { GenericButton } from '../commons/generic-input/GenericButton';
import { apiDelete } from '../../helpers/api';
import { toast } from 'react-toastify';
import { AuthContext } from '../../contexts/AuthContext';
import { Avatar } from '../commons/avatar/Avatar';
import { FaArrowRight, FaEllipsisV } from 'react-icons/fa';

import styles from "./styles/expensesList.module.scss";

export const ExpensesList = ({ room, onRemove, onEdit, isAdmin }) => {
    const [modalToShow, setModalToShow] = useState(null);
    const [selectedExpense, setSelectedExpense] = useState(null);
    const [itemsToShow, setItemsToShow] = useState(100);

    const { value } = useContext(AuthContext);
    const id = value?.storagedData?.id;

    const handleDelete = () => {
        apiDelete(`${selectedExpense.type === "expense" ? 'expenses' : 'payments'}/${selectedExpense.id}`)
            .then(response => {
                if (response.status !== 200 && response.status !== 204) {
                    throw new Error(response.status)
                }
                return response;
            })
            .then(() => onRemove())
            .catch(error => toast.error(`Error ${error}`))
        setModalToShow(null);
    }

    const getUser = (userId) => {
        return room?.users.find(user => user.id === userId);
    }

    if (!room) return null;

    const items = [
        ...room.expenses.map(expense => ({ ...expense, type: 'expense' })),
        ...room.payments.map(payment => ({ ...payment, type: 'payment' }))
    ].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    return (
        <div className={styles['expenses-list-container']} style={{ marginBottom: '10px' }}>
            <div className={styles.title}>Detalle</div>

            <div className={styles.list}>
                {
                    [...items].splice(0,itemsToShow).map(item => {

                        const payer = getUser(item.userId || item.payerId);

                        return (
                            <div className={styles.expenseContainer}
                                key={`${item.id}-${item.type}`}
                                onClick={() => { setSelectedExpense(item); setModalToShow('show') }}
                            >
                                <div className={styles.expense}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div>
                                            <div className={styles.price} style={{ fontSize: 18, fontWeight: 500 }}>
                                                <span>{showPrice(item.value)}</span>
                                            </div>
                                            <div className={styles.description} style={{ display: "flex", gap: 5 }}>
                                                <span style={{ fontSize: 14, color: item.type === 'payment' ? "#6c9d09" : "" }}>{item.description}</span>
                                            </div>
                                        </div>

                                        <div>
                                            {
                                                (item.userId === id || item.payerId === id || isAdmin) && (
                                                    <Popup
                                                        trigger={
                                                            <span className={styles.actions}>
                                                                <FaEllipsisV />
                                                            </span>
                                                        }
                                                        position="left center"
                                                    >
                                                        <div
                                                            className={classNames(styles['expense-options'], 'animate__animated', 'animate__fadeInRight')}
                                                        >
                                                            {
                                                                item.type !== "payment" && (
                                                                    <div className={styles.option} onClick={() => { setSelectedExpense(item); setModalToShow('edit') }}>
                                                                        Editar
                                                                    </div>
                                                                )
                                                            }
                                                            <div className={styles.option} style={{ color: '#e34848' }} onClick={() => { setSelectedExpense(item); setModalToShow('delete') }}>
                                                                Eliminar
                                                            </div>
                                                        </div>
                                                    </Popup>
                                                )
                                            }



                                        </div>
                                    </div>

                                    <hr />


                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ display: "flex", gap: 5, alignItems: "center" }}>
                                            <div style={{ display: "flex", gap: 3, alignItems: "center" }}>
                                                <Avatar size={"xsm"} url={payer.avatar} />
                                                <span style={{ fontSize: 12, color: "#666" }}>{payer.username}</span>
                                            </div>

                                            <FaArrowRight style={{ fontSize: 10, color: "#666" }} />

                                            {
                                                item.type === 'expense' ? (
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <div>
                                                            <div
                                                                className={classNames(styles.participants, { [styles.omitedParticipants]: item.participants.length > 6 })}
                                                            >
                                                                {
                                                                    room?.users.length > 1 && item.participants.slice(0, (item.participants.length > 8 ? 7 : 8)).map(participant => {
                                                                        const user = getUser(participant);
                                                                        return (
                                                                            <div className={styles.userImg} key={user.id}>
                                                                                <Avatar size={"xsm"} url={user?.avatar} />
                                                                            </div>
                                                                        )
                                                                    })
                                                                }

                                                                {
                                                                    item.participants.length > 8 && (
                                                                        <div className={styles.participantsOmited}>
                                                                            <span>
                                                                                +{item.participants.length - 7}
                                                                            </span>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div style={{ display: "flex", gap: 3, alignItems: "center" }}>
                                                        <Avatar url={getUser(item.receiverId).avatar} size={"xsm"} />
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div>
                                            <span style={{ fontSize: 12, color: "#666" }}>{dateToString(item.createdAt)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                {
                    (itemsToShow < items.length) && <GenericButton variant="tertiary" fitContent fullWidth text="Ver mas" onClick={() => setItemsToShow(prev => prev + 100)} />
                }
            </div>

            {
                !items?.length && (<span style={{ color: '#666', fontSize: 14 }}>No hay movimientos</span>)
            }

            <br /><br />

            {/* edit modal */}
            {
                modalToShow === 'edit' && (
                    <Modal show={true} onClose={() => setModalToShow(null)} title={`Editando "${selectedExpense.description}"`}>
                        <ExpenseEditOrCreateForm
                            expense={selectedExpense}
                            onSubmitted={() => { onEdit(); setModalToShow(null); }}
                            btnText={'Guardar cambios'}
                            room={room}
                        />
                    </Modal>
                )
            }

            {/* view modal */}
            {
                modalToShow === 'show' && (
                    <Modal show={true} onClose={() => setModalToShow(null)}>
                        <div className={classNames(styles['expense-modal-detail'])}>
                            <div className={styles['expense-info']}>
                                <h3>
                                    {showPrice(selectedExpense.value)}
                                </h3>
                                {
                                    selectedExpense.type === "expense" ? (
                                        <p className={styles['description']}>{selectedExpense.description}</p>
                                    ) : (
                                        <p className={styles['description']}>Pago a {getUser(selectedExpense.receiverId).username}</p>
                                    )
                                }
                                <hr />
                                <p>Echo por {getUser(selectedExpense.userId || selectedExpense.payerId).username} • {dateToString(selectedExpense.createdAt)}</p>
                            </div>
                            {
                                selectedExpense.type === "expense" && (
                                    <>
                                        <hr />
                                        <div className={styles['expense-participants']}>
                                            <h5>Participantes</h5>
                                            {
                                                room.users.map(p => {
                                                    return selectedExpense.participants.includes(p.id)
                                                        && (
                                                            <div key={p.id} className={styles['expense-participant-info']}>
                                                                <div className={styles.box}>
                                                                    <img src={p.avatar} alt={p.username} referrerPolicy="no-referrer" />
                                                                    <span>{p.username}</span>
                                                                </div>
                                                                <span className={styles['expense-participant-value']}>
                                                                    {showPrice(selectedExpense.value / selectedExpense.participants.length)}
                                                                </span>
                                                            </div>
                                                        )
                                                })
                                            }
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </Modal>
                )
            }

            {/* delete modal */}
            {
                modalToShow === 'delete' && (
                    <Modal show={true} onClose={() => setModalToShow(null)} title={`Eliminar "${selectedExpense.description}""`}>
                        <p>Se eliminarara permanentemente</p>
                        <br />
                        <GenericButton variant="red" text="Eliminar" onClick={handleDelete} />
                    </Modal>
                )
            }

        </div>
    )
}
