import { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContext';

export const PublicRoute = ({ children }) => {
    const { value: authValue } = useContext(AuthContext);

    const isLogged = !!authValue?.storagedData?.id;
    return !isLogged
        ? children
        : <Navigate to="/" />
}
