import { useContext } from 'react'
import { ActionList } from './ActionList';
import { TbLogout } from "react-icons/tb";
import { ToggleDarkMode } from '../commons/toggle-dark-mode/ToggleDarkMode';
import { AuthContext } from '../../contexts/AuthContext';
import { RoomsContext } from '../../contexts/RoomsContext';
import { InfoFrame } from './InfoFrame';

import styles from "./styles/profileScreen.module.scss";

export const ProfileScreen = () => {

    const {value, logout} = useContext(AuthContext);
    const {rooms, archivedRooms} =  useContext(RoomsContext);

    return (
        <div className={styles.container}>
            <div className={styles.infoCard}>
                <div className={styles.avatarContainer}>
                    <img
                        src={value.storagedData.avatar}
                        alt={value.storagedData.username}
                        style={{ display: 'block', margin: 'auto', maxWidth: '200px' }}
                    />
                </div>

                <div className={styles.infoContainer}>
                    <h4>{value.storagedData.username}</h4>
                    <span>{value.storagedData.email}</span>
                </div>
            </div>

            <br />

            <InfoFrame activeRooms={rooms?.length || 0} archivedRooms={archivedRooms?.length || 0}/>

            <ToggleDarkMode />

            <br />

            <ActionList actions={[
                {
                    label: "Cerrar sesion",
                    variant: "red",
                    icon: <TbLogout size={20} />,
                    onClick: logout
                }
            ]}/>


        </div>
    )
}
