import { useContext, useState } from 'react'
import { GoogleLogin } from 'react-google-login';
import { useForm } from '../../../hooks/useForm';
import { GenericInput } from '../../commons/generic-input/GenericInput';
import { Link, useNavigate } from 'react-router-dom';
import { GenericButton } from '../../commons/generic-input/GenericButton';
import { AuthContext } from '../../../contexts/AuthContext';
import { apiPost } from '../../../helpers/api';
import { toast } from 'react-toastify';

import styles from "../styles/auth.module.scss";

export const LoginScreen = () => {

    const { setValue } = useContext(AuthContext);

    const navigate = useNavigate();

    const [formErrors, setFormErrors] = useState({});

    const [submitted, setSubmitted] = useState(false);

    const [formValues, handleInputChange] = useForm({
        name: '',
        password: ''
    })

    const { name, password } = formValues;


    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (isFormValid()) {
            apiPost('login', { username: name, password: password })
                .then(response => response.json())
                .then(result => {
                    if ([404, 301].includes(result.status)) {
                        toast.error(result.userMessage);
                    } else {
                        localStorage.setItem('sessionData', JSON.stringify(result));
                        setValue({
                            storagedData: result
                        })
                        navigate("/");
                    }
                })
                .catch(error => { toast.error(error) });
        }
    }

    const onSuccessResponseGoogle = (e) => {
        setTimeout(() => {
            apiPost('social-login', { token: e.tokenId, username: e.profileObj.name })
                .then(response => {
                    if (response.status === 418) {
                        localStorage.setItem('temporalUserToken', e.tokenId);
                        localStorage.setItem('temporalUsername', e.profileObj.name)
                        navigate('/register-username');
                    }
                    return response.json()
                })
                .then(result => {
                    if (result.status === 404 || result.status === 301) {
                        toast.error('Ocurrio un error');
                    } else {
                        localStorage.setItem('sessionData', JSON.stringify(result));
                        setValue({
                            storagedData: result
                        })
                        navigate("/");
                    }
                })
                .catch(error => { toast.error(error) });
        }, 500);
    }

    const isFormValid = () => {
        const errors = {};
        if (!name) {
            errors['name'] = 'El nombre no puede estar vacio';
        }
        if (!password) {
            errors['password'] = 'La contraseña no puede estar vacia';
        }
        setFormErrors({ ...errors });
        return Object.keys(errors).length === 0;
    }

    return (
        <div style={{ maxWidth: '400px', margin: 'auto', padding: '20px' }}>
            <div style={{ textAlign: 'center', marginBottom: '30px' }}>
                <img src="/undraw_login.svg" alt="" style={{ height: '200px' }} />
            </div>

            <form onSubmit={handleSubmit} autoComplete="off">
                <GenericInput
                    label="Usuario"
                    placeholder="Ingresa tu usuario"
                    name="name"
                    value={name}
                    onChange={handleInputChange}
                    refreshErrors={isFormValid}
                    errors={formErrors['name']}
                    submitted={submitted}
                />
                <GenericInput
                    type="password"
                    label="Contraseña"
                    placeholder="Ingresa tu contraseña"
                    name="password"
                    value={password}
                    onChange={handleInputChange}
                    refreshErrors={isFormValid}
                    errors={formErrors['password']}
                    submitted={submitted}
                />
                <GenericButton text="Ingresar" onClick={handleSubmit} />
            </form>

            <GoogleLogin
                clientId="800015004819-n6i816vd9cgsi9ogg180tqt6p6li9dql.apps.googleusercontent.com"
                buttonText="Ingresar con Google"
                render={renderProps => (
                    <button className={styles.googleButton} onClick={renderProps.onClick}>

                        <img src="/google.svg" alt="google icon" /> Ingresar con Google
                    </button>
                )}
                onSuccess={onSuccessResponseGoogle}
                onFailure={(e) => console.error(e)}
                cookiePolicy={'single_host_origin'}
            />

            <hr />

            <div className={styles.authRedirectContainer}>
                <Link to="/register">Crear cuenta!</Link>
            </div>
        </div>
    )
}
