import { useContext } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LoginScreen } from '../components/auth/login/LoginScreen';
import { RegisterScreen } from '../components/auth/register/RegisterScreen';
import { RegisterUsername } from '../components/auth/register/RegisterUsername';
import { Loader } from '../components/commons/loader/Loader';
import { AppRoutes } from './AppRoutes';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { UiContext } from '../contexts/UiContext';
import { useIsFetching } from 'react-query';

export const AppRouter = () => {
    const { value: uiValue } = useContext(UiContext);
    const isFetching = useIsFetching();

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={
                    <PublicRoute>
                        <LoginScreen />
                    </PublicRoute>
                } />

                <Route path="/register" element={
                    <PublicRoute>
                        <RegisterScreen />
                    </PublicRoute>
                } />

                <Route path="/register-username" element={
                    <PublicRoute>
                        <RegisterUsername />
                    </PublicRoute>
                } />

                <Route path="/*" element={
                    <PrivateRoute>
                        <AppRoutes />
                    </PrivateRoute>
                }
                />
            </Routes>
            {
                (uiValue.loading || Boolean(isFetching)) && <Loader />
            }
        </BrowserRouter>
    )
}
