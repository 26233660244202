import styles from './styles/manageUsers.module.scss';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useContext, useState } from 'react';
import { apiPatch } from '../../helpers/api';
import { toast } from 'react-toastify';
import { AddParticipantsSelector } from './commons/AddParticipantsSelector';
import { Avatar } from '../commons/avatar/Avatar';
import { GenericButton } from '../commons/generic-input/GenericButton';
import useModal from '../../hooks/useModal';
import { RoomContext } from '../../contexts/RoomContext';

const UserContainer = ({ user, roomId, editable, onUpdate }) => {

    const [showOptions, setShowOptions] = useState(false);

    const handleMakeAdmin = () => {
        apiPatch(`rooms/${roomId}/admin/${user.id}`)
            .then(() => onUpdate())
            .catch(error => toast.error(error))
            .finally(() => setShowOptions(false));
    }

    return (
        <div>
            <div key={user.id} className={styles.userContainer}>
                <div className={styles.user}>
                    <Avatar size={"sm"} url={user.avatar} title={user.username} />
                    {user.username} {user.isAdmin && <small>Admin</small>}
                </div>
                {
                    editable && (
                        showOptions ? (
                            <span onClick={() => setShowOptions(false)}>✕</span>
                        ) : (
                            <BsThreeDotsVertical onClick={() => setShowOptions(true)} />
                        )
                    )
                }

            </div>
            {
                showOptions && (
                    <div style={{ marginLeft: 40, marginBottom: 10, fontSize: 14 }} onClick={handleMakeAdmin}>
                        {user.isAdmin ? 'Quitar rol' : 'Designar como admin'}
                    </div>
                )
            }
        </div>
    )
}

export const ManageUsers = () => {
    const { Modal, setModal, hideModal } = useModal();

    const { value, refetch } = useContext(RoomContext);

    return (
        <div className={styles.container}>
            {
                value.users.map(user => <UserContainer key={user.id} user={user} roomId={value.id} editable={value.isAdmin} onUpdate={refetch} />)
            }

            {
                value.isAdmin && (
                    <GenericButton
                        text="Agregar participante"
                        onClick={() => setModal(<AddParticipantsSelector participants={value.users} onUpdate={() => { hideModal(); refetch(); }} />, { title: "Nuevo Participante" })}
                        variant="secondary"
                    />
                )
            }

            <Modal />
        </div>
    )
}
