import { createContext, useState } from 'react';

export const UiContext = createContext();

export const UiProvider = ({ children }) => {
    const [value, setValue] = useState({
        loading: false
    });

    return (
        <UiContext.Provider value={{ value, setValue }}>
            {children}
        </UiContext.Provider>
    );
};
