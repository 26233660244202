import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { HelmetProvider } from "react-helmet-async";
import { AppRouter } from './routers/AppRouter';
import { UiProvider } from './contexts/UiContext';
import { AuthProvider } from './contexts/AuthContext';
import { initializeAnalytics, logPageView } from './helpers/analitycs';
import { QueryClient, QueryClientProvider } from 'react-query';

import 'react-toastify/dist/ReactToastify.css';

export const queryClient = new QueryClient();

const App = () => {

	useEffect(() => {
		const theme = localStorage.getItem('theme') || 'light-theme'
		document.body.classList.add(theme);
	}, [])

	useEffect(() => {
		initializeAnalytics();
		logPageView();
	}, []);


	return (
		<QueryClientProvider client={queryClient}>
			<AuthProvider>
				<UiProvider>
					<HelmetProvider>
						<AppRouter />
						<ToastContainer
							position="bottom-right"
							autoClose={5000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
						/>
					</HelmetProvider>
				</UiProvider>
			</AuthProvider>
		</QueryClientProvider>
	);
}

export default App;
