import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { dateToString } from '../../../helpers/functions';
import { toast } from 'react-toastify';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { apiPost } from '../../../helpers/api';
import { FaHeart, FaRegClock, FaRegHeart } from 'react-icons/fa'

import styles from "./styles/roomItem.module.scss";

export const RoomItem = ({ data, isArchived, onUpdate }) => {

    const navigate = useNavigate();

    const handleOnClick = () => {
        navigate(`/room/${data.id}`);
    }

    const handleFavorite = (e) => {
        e.stopPropagation();

        apiPost(`rooms/favorite/${data.id}`, {})
            .then(response => response.text())
            .then(() => {
                onUpdate();
                if (data.isFavorite) {
                    toast.success(`Se quito ${data.name} de favoritos`)
                } else {
                    toast.success(`Se agrego ${data.name} a favoritos`)
                }
            })
            .catch(error => alert(error));
    }

    const showValue = (value) => {
        const number = value < 0 ? value * -1 : value;
        if (number < 1 && number > 0) {
            return number.toFixed()
        }
        if (number > 999) {
            return `${(number / 1000).toFixed()}k`
        }
        return parseInt(number)
    }

    return (
        <div className={styles.roomItem} onClick={handleOnClick}>
            <img
                src={data?.coverpage.url}
                alt={data.name}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = "https://hornmagazine.online/wp-content/uploads/sites/26/2021/02/No-Image.jpg";
                }}
            />

            {
                !isArchived && (
                    <span
                        className={classNames(styles.favoriteField, { [styles.isFavorite]: data.isFavorite })}
                        onClick={handleFavorite}
                    >
                        {
                            data.isFavorite ? <FaHeart /> : <FaRegHeart />
                        }
                    </span>
                )
            }

            <div className={styles.titleContainer}>
                <span
                    className={styles.title}
                >
                    {
                        isArchived && (
                            <span className={styles.archivedField}>Sala archivada</span>
                        )
                    }
                    {data.name}
                </span>

                <div style={{display: "flex"}}>
                    {
                        !!data.balance && (
                            <span className={classNames(styles.balance, { [styles.positive]: data.balance > 0 })}>
                                {data.balance > 0 ? <AiFillCaretUp /> : <AiFillCaretDown />}
                                ${showValue(data.balance)}
                            </span>
                        )
                    }
                    <span className={styles.date}>
                        <FaRegClock /> {dateToString(data.lastUpdate, false)}
                    </span>
                </div>

                <div
                    className={classNames(styles.participants, { [styles.omitedParticipants]: data?.users?.length > 4 })}
                >
                    {
                        data?.users?.slice(0, (data.users.length > 3 ? 2 : 3)).map(user => (
                            <div className={styles.partcipantImg} key={`${data.id}-${user.id}`}>
                                <img
                                    alt={user.name}
                                    title={user.username}
                                    src={user?.avatar}
                                    referrerPolicy="no-referrer"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = "https://hornmagazine.online/wp-content/uploads/sites/26/2021/02/No-Image.jpg";
                                    }}
                                />
                            </div>
                        ))
                    }

                    {
                        data?.users?.length > 3 && (
                            <div className={styles.participantsOmited}>
                                <span>
                                    +{data.users.length - 2}
                                </span>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}
