import styles from "./loader.module.scss";

export const Loader = () => {

    return (
        <div className={styles['loader-container']}>
            <div className={styles['shapes-5']}></div>
            {/* <img className="loader-gif" src="https://cdn.dribbble.com/users/189524/screenshots/2822794/silhouette-solo-loader-dribbble_v2.gif" alt="" /> */}
        </div>
    )
}
