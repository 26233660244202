import classNames from "classnames";
import styles from "./styles/genericInput.module.scss";

export const GenericInput = ({ type = 'text', placeholder, name, value, onChange, label, errors, refreshErrors, submitted, min, disabled, inputRef }) => {
    return (
        <div className={styles['generic-input-container']}>
            <span className={styles['generic-input-label']}>{label}</span>
            <input
                className={classNames(styles['generic-input'], { [styles['invalid-input']]: errors && submitted })}
                placeholder={placeholder}
                type={type}
                name={name}
                value={value}
                step="0.01"
                onChange={onChange}
                onKeyUp={refreshErrors}
                autoComplete="off"
                min={min}
                disabled={disabled}
                ref={inputRef}
            />

            {
                (errors && submitted) && (
                    <div className={styles.errorMsg}>
                        {errors}
                    </div>
                )
            }
        </div>
    )
}
