import { useEffect, useRef, useState } from 'react'
import { useForm } from '../../../hooks/useForm'
import { ParticipantsSelector } from '../commons/ParticipantsSelector';
import { GenericDatePicker } from '../../commons/generic-input/GenericDatePicker';
import { GenericInput } from '../../commons/generic-input/GenericInput';
import { GenericButton } from '../../commons/generic-input/GenericButton';
import { apiPost, apiPut } from '../../../helpers/api';
import { toast } from 'react-toastify';
import { GenericSelect } from '../../commons/generic-input/GenericSelect';

export const ExpenseEditOrCreateForm = ({ onSubmitted, userId, expense, btnText, room, isReadOnly }) => {

    const inputValueRef = useRef();

    const [formValues, handleInputChange, resetForm, changeValue] = useForm({
        value: expense?.value || '',
        description: expense?.description || '',
        participants: room?.users || [],
        date: expense?.createdAt || new Date().toISOString(),
    })

    const { value, description, participants } = formValues;

    const [expenseOwner, setExpenseOwner] = useState(userId);

    const [formErrors, setFormErrors] = useState({});

    const [submitted, setSubmitted] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        inputValueRef.current.focus();
    }, [])

    const isFormValid = () => {
        const errors = {};
        if (!value) {
            errors['value'] = 'El valor no puede estar vacio';
        }
        if (!description.trim()) {
            errors['description'] = 'La descripcion no puede estar vacia';
        }
        if (!participants.length) {
            errors['participants'] = 'Campo invalido';
        }
        setFormErrors({ ...errors });
        return Object.keys(errors).length === 0;
    }

    const handleOnSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (isFormValid()) {
            setIsLoading(true);
            if (expense) {
                apiPut('expenses', {
                    ...expense,
                    description: formValues.description,
                    value: formValues.value,
                    participants: formValues.participants.map(participant => participant.id),
                    date: formValues.date,
                })
                    .then(response => {
                        if (response.status !== 200) throw new Error(response.status)
                        return response.json()
                    })
                    .then(() => {onSubmitted(); resetForm();})
                    .catch(error => toast.error(`Error ${error}`))
                    .finally(() => setIsLoading(false))
            } else {
                apiPost('expenses', {
                    description: formValues.description,
                    value: formValues.value,
                    userId: expenseOwner,
                    participants: formValues.participants.map(participant => participant.id),
                    roomId: room.id,
                    date: formValues.date,
                })
                    .then(response => response.json())
                    .then(() => {onSubmitted(); resetForm();})
                    .catch(error => toast.error(`Error: ${error}`))
                    .finally(() => setIsLoading(false));
            }
        }
    }

    useEffect(() => {
        if (expense) {
            let participantsUsers = [];
            room?.users.forEach(user => {
                if (expense.participants.includes(user.id)) {
                    participantsUsers.push(user);
                }
            });
            changeValue('participants', participantsUsers)
        } else {
            changeValue('participants', room?.users)
        }
    }, [room]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <form onSubmit={handleOnSubmit} className={isReadOnly ? 'no-click' : ''}>
            <GenericInput
                placeholder="Ingresa el valor"
                name="value"
                value={value}
                onChange={handleInputChange}
                label="Valor"
                refreshErrors={isFormValid}
                errors={formErrors['value']}
                submitted={submitted}
                type="number"
                min={1}
                inputRef={inputValueRef}
            />

            <GenericInput
                placeholder="Ingresa una descripcion"
                name="description"
                value={description}
                onChange={handleInputChange}
                label="Descripcion"
                refreshErrors={isFormValid}
                errors={formErrors['description']}
                submitted={submitted}
            />

            {
                !expense && room.isAdmin && (
                    <div>
                        <small style={{fontSize: 12}}>Echo por</small>
                        <GenericSelect
                            value={expenseOwner}
                            options={room.users.map(user => ({ value: user.id, description: user.username }))}
                            onChangeSelect={(e) => setExpenseOwner(e.target.value)}
                        />
                    </div>
                )
            }

            <ParticipantsSelector
                participants={room?.users || []}
                expenseParticipants={formValues.participants}
                onParticipantsChange={(e) => changeValue('participants', e)}
                expenseValue={value}
            />

            <GenericDatePicker
                value={expense?.createdAt || null}
                onChangeValue={(newValue) => changeValue('date', new Date(newValue))}
            />

            {
                !isReadOnly && (
                    <GenericButton loading={isLoading} onClick={handleOnSubmit} text={btnText} />
                )
            }
        </form>
    )
}
