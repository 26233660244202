import { useContext, useEffect, useState } from 'react';
import { ExpensesList } from './ExpensesList';
import { ParticipantsList } from './ParticipantsList';
import { RoomBanner } from './room-banner/RoomBanner';
import { GenericButton } from '../commons/generic-input/GenericButton';
import { RoomContext, RoomProvider } from '../../contexts/RoomContext';
import useModal from '../../hooks/useModal';
import { AuthContext } from '../../contexts/AuthContext';
import { ExpenseEditOrCreateForm } from './expense/ExpenseEditOrCreateForm';
import { PaymentForm } from './payment/PaymentForm';

import styles from "./styles/roomScreen.module.scss";

const InnerRoomScreen = () => {
    const { value: data, refetch } = useContext(RoomContext)

    const { name, users, description, coverpage, id, isAdmin } = data || {};

    const { value } = useContext(AuthContext);
    const userId = value?.storagedData?.id;

    const { Modal, setModal, hideModal, changeModalTitle } = useModal();

    const [balanceDetail, setBalanceDetail] = useState({});
    const [totalDetail, setTotalDetail] = useState({});

    useEffect(() => {
        const tempBalance = {};
        const tempTotal = {};
        users?.forEach(user => {
            tempTotal[user.id] = parseFloat(tempTotal[user.id] || 0) + user.spent;
            tempBalance[user.id] = (parseFloat(tempBalance[user.id] || 0) + user.balance)?.toFixed(2);
        })
        setTotalDetail(tempTotal);
        setBalanceDetail(tempBalance);
    }, [users])

    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, [])

    useEffect(() => {
        if (data) document.title = 'PayRoom | ' + data.name;
    }, [data]);

    return (
        <>
            <RoomBanner
                title={name || "..."}
                persons={users || 0}
                img={coverpage?.url}
                id={id || null}
                room={data || {}}
                isArchived={data?.state === "ARCHIVED"}
                onRefetch={refetch}
            />

            {
                description && (
                    <div>
                        <h3 className={styles.title}>Descripción</h3>
                        <span className={styles.description}>
                            {description}
                        </span>
                    </div>
                )
            }

            <ParticipantsList
                participants={users}
                expensesDetail={balanceDetail}
                totalDetail={totalDetail}
                isAdmin={isAdmin}
            />

            {/* // TODO: hacer sticky */}
            {
                data?.active && (
                    <div style={{ display: "flex", gap: 10, position: "fixed", width: "100%", bottom: 0, zIndex: 1, padding: 15, left: 0, justifyContent: "center" }}>
                        <div style={{ maxWidth: 1024, width: "100%", display: "flex", gap: 10, flexDirection: "column" }}>
                            {
                                balanceDetail[userId] < 0 && (
                                    <GenericButton shadow text="Registrar pago" onClick={() => setModal(() =>
                                        <PaymentForm
                                            userId={userId}
                                            room={data}
                                            balanceDetail={balanceDetail}
                                            changeModalTitle={changeModalTitle}
                                            onSubmitted={() => { hideModal(); refetch() }}
                                        />, { title: "Registrar pago" })}
                                        variant="secondary"
                                    />
                                )
                            }

                            {
                                Boolean(users.length > 1) && (
                                    <GenericButton
                                        text="Registrar gasto"
                                        onClick={() => setModal(
                                            <ExpenseEditOrCreateForm
                                                userId={userId}
                                                btnText={'Registrar gasto'}
                                                onSubmitted={() => { hideModal(); refetch() }}
                                                room={data}
                                            />, {
                                            title: `Nuevo gasto`
                                        })}
                                        shadow
                                    />
                                )
                            }
                        </div>
                    </div>
                )
            }

            <br />

            <ExpensesList room={data} onRemove={refetch} onEdit={refetch} isAdmin={isAdmin} />

            <Modal />
        </>
    )
}

export const RoomScreen = () => {
    return (
        <RoomProvider>
            <InnerRoomScreen />
        </RoomProvider>
    )
}