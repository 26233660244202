import classNames from "classnames"

import styles from "./modal.module.scss";

export const Modal = ({ show, onClose, children, title }) => {

    if (!show) return null;

    return (
        <div className={classNames(styles['modal-container'], 'animate__animated', 'animate__fadeIn')} onClick={onClose}>
            <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                <div className={styles['modal-title']}>
                    <h3>{title}</h3>
                </div>
                <div className={classNames('modal-body', styles['expense-modal-detail'])}>
                    {children}
                </div>
                <span className={styles['close-button']} onClick={onClose}>
                    ✕
                </span>
            </div>
        </div>
    )
}
