const prodUrl = "https://api.nodev.com.ar/PayRooms/api/v1.0/";
// const devUrl = "http://192.168.1.122:8080/PayRooms/api/v1.0/"
const url = prodUrl;

const apiPost = (path, body) => {
    const token = JSON.parse(localStorage.getItem('sessionData'))?.token || '';
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    if (token) {
        myHeaders.append("Authorization", token);
    }

    var raw = JSON.stringify(body);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return fetch(`${url}${path}`, requestOptions);
}

const apiPostWithAppend = (path, body) => {
    const token = JSON.parse(localStorage.getItem('sessionData'))?.token || '';
    var myHeaders = new Headers();
    if (token) {
        myHeaders.append("Authorization", token);
    }

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: body
    };

    return fetch(`${url}${path}`, requestOptions);
}

const apiGet = (path) => {
    const token = JSON.parse(localStorage.getItem('sessionData'))?.token || '';
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
    };

    return fetch(`${url}${path}`, requestOptions)
        .then(res => {
            if (res.status === 403) {
                window.location.replace("/");
            }
            return res
        })
}

const apiPut = (path, body) => {
    const token = JSON.parse(localStorage.getItem('sessionData'))?.token || '';
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);

    var raw = JSON.stringify(body);

    var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return fetch(`${url}${path}`, requestOptions);
}

const apiDelete = (path) => {
    const token = JSON.parse(localStorage.getItem('sessionData'))?.token || '';
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${url}${path}`, requestOptions);
}

const apiPatch = (path) => {
    const token = JSON.parse(localStorage.getItem('sessionData'))?.token || '';
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);

    var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${url}${path}`, requestOptions);
}

export {
    apiPost,
    apiPostWithAppend,
    apiGet,
    apiPut,
    apiDelete,
    apiPatch
}