import { useState } from 'react';
import { apiPostWithAppend, apiPut } from '../../helpers/api';
import { useForm } from '../../hooks/useForm'
import { CoverPageSelector } from '../commons/cover-page-selector/CoverPageSelector';
import { GenericButton } from '../commons/generic-input/GenericButton';
import { GenericInput } from '../commons/generic-input/GenericInput'

export const EditRoom = ({ room, onSubmit }) => {
    const [formErrors, setFormErrors] = useState({});

    const [submitted, setSubmitted] = useState(false);

    const [uploadImage, setUploadImage] = useState(null);

    const [formValues, handleInputChange, , changeValue] = useForm({
        name: room?.name || '',
        description: room?.description || '',
        coverpage: room?.coverpage?.id || '',
        owner: -1
    });

    const { name, description, coverpage } = formValues;

    const isFormValid = () => {
        const errors = {};
        if (!name) {
            errors['name'] = 'El nombre no puede estar vacio';
        }
        if (!coverpage) {
            errors['coverpage'] = 'Se debe seleccionar una imagen de portada';
        }
        setFormErrors({ ...errors });
        return Object.keys(errors).length === 0;
    }

    const handleSelectCover = (coverId) => {
        isFormValid();
        changeValue('coverpage', coverId);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (coverpage !== -1) {

            apiPut('rooms', {
                ...room,
                name: formValues.name,
                description: formValues.description,
                coverpage: coverpage
            })
                .then(() => {
                    setSubmitted(true);
                    onSubmit();
                })
                .catch(error => console.error(error));
        } else {
            var data = new FormData();
            data.append('file', uploadImage);
            data.append('name', uploadImage.name);

            apiPostWithAppend('coverpages', data)
                .then(response => response.json())
                .then((result) => {
                    apiPut('rooms', {
                        ...room,
                        name: formValues.name,
                        description: formValues.description,
                        coverpage: result.id
                    })
                        .then(() => {
                            setSubmitted(true);
                            onSubmit();
                        })
                        .catch(error => console.error(error));
                });
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <GenericInput
                    placeholder="Ingresa el nombre de la sala"
                    name="name"
                    value={name}
                    onChange={handleInputChange}
                    label="Nombre"
                    refreshErrors={isFormValid}
                    errors={formErrors['name']}
                    submitted={submitted}
                />
                <GenericInput
                    placeholder="Ingresa una descripcion"
                    name="description"
                    value={description}
                    onChange={handleInputChange}
                    label="Descripcion"
                    refreshErrors={isFormValid}
                    errors={formErrors['description']}
                    submitted={submitted}
                />
                <CoverPageSelector currentCover={room.coverpage} onSelectCover={handleSelectCover} onSelectUpload={setUploadImage} error={submitted && formErrors['coverpage']} />
                <GenericButton text="Guardar" onClick={handleSubmit} />
            </form>
        </div>
    )
}
